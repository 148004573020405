import { Controller } from "@hotwired/stimulus"
import balanceText from "balance-text"

export default class extends Controller {
  static values = {
    matchElementTypes: { type: Array, default: ["div"] }
  }
  
  connect() {
    this.typographize(this.element)
  }
  
  typographize(element) {
    if (element.children.length > 0) {
      for (const childElement of element.children) {
        this.typographize(childElement)
      }
    }
    
    if (this.shouldApplyTypographyToElement(element)) {
      balanceText(element, { watch: true })
    }
  }
  
  elementIsMatchType(element) {
    const elementType = element.nodeName.toLowerCase()
    return this.matchElementTypesValue.includes(elementType)
  }
  
  shouldApplyTypographyToElement(element) {
    return (this.elementIsMatchType(element) && !this.elementHasMatchingChildren(element))
  }
  
  elementHasMatchingChildren(element) {
    const children = element.children
    
    let matchFound = false
    let i = 0
    
    while (!matchFound && (i < children.length)) {
      let child = children[i]
      matchFound = this.elementIsMatchType(child)
      i++
    }
    
    return matchFound
  }
}
